import React, { useEffect } from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import Quote from '@/components/Quote'
import { graphql } from 'gatsby'
import { getSanitizedLocation } from '@/modules/utils/location'
import { useContentInProp } from '@/hooks/useContentInProp'
import { Left, RightOnSmaller } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'
import { CustomNavigate } from '@/modules/utils/CustomNavigate'
import PubSub from 'pubsub-js'
import {
  APP_SWITCH_BACKGROUND_THREE_SCENE,
  APP_PAGE_ANIMATION_COMPLETED,
} from '@/events'

export const query = graphql`
  query cmsOhpeneer($id: String) {
    cmsOhpeneer(id: { eq: $id }) {
      id
      slug
      text
      status
      created_on
      name
      function
      image {
        data {
          url
        }
      }
      quote
      featured
      overview
      detail_image {
        data {
          url
        }
      }
    }
  }
`

const OhpeneerPage: React.FC = ({ data }) => {
  const inProp = useContentInProp()
  const isServer = typeof window === 'undefined'
  const currentPath = getSanitizedLocation(
    isServer ? '' : window.location.pathname,
  )
  const rootPath = '/our-dna/ohpeneer/'
  const ohpeneerName = currentPath.replace(rootPath, '')
  const ohpeneer = data.cmsOhpeneer
  if (!ohpeneer) {
    if (!isServer) {
      CustomNavigate('/')
    }
    return null
  }
  const general = {
    pretitle: 'Meet our Ohpeneers',
    title: ohpeneerName.replace(/-+/g, ' '),
    links: [
      {
        link: 'other ohpeneers',
        url: '/our-dna/ohpeneer-overview',
        variant: 'all',
      },
      {
        link: `Work with ${ohpeneerName.replace(/-+/g, ' ').split(' ')[0]}`,
        url: '/careers',
        variant: 'xsOnly',
      },
    ],
  }
  const leftText = { ...ohpeneer, ...general }
  /* eslint-disable camelcase */

  useEffect(() => {
    if (ohpeneer?.detail_image?.data?.url) {
      setImmediate(() => {
        PubSub.publish(APP_SWITCH_BACKGROUND_THREE_SCENE, {
          name: ohpeneer.slug,
          pageReadyDelay: 3,
          mediaSettings: {
            mediaHTMLElement: ohpeneer?.detail_image?.data?.url,
            position: { x: 0.5, y: 0.5 },
            crop: 'right',
          },
          colorBlockSettings: {
            desktopType: 'right', // "none" "right" "left" "full"
            slideAnimations: ['fromTopInverted', 'none'], // "none" "fromTop" "fromBottom"
            slideAnimationsMobile: ['fromTop', 'none'], // "none" "fromTop" "fromBottom"
            blockColor1: { r: 19 / 255, g: 50 / 255, b: 43 / 255 }, // left side of block gradient
            blockColor2: { r: 19 / 255, g: 50 / 255, b: 43 / 255 }, // right side of block gradient
          },
        })
        PubSub.publish(APP_PAGE_ANIMATION_COMPLETED)
      })
    }
  }, [])

  /* eslint-enable camelcase */

  return (
    <>
      <Left>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="left"
            delay={0}
            time={1}
            url="/our-dna/ohpeneer-overview"
            text="back"
          />
        )}
      </Left>
      <RightOnSmaller>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="right"
            delay={1.1}
            time={1}
            url="/careers"
            text={`Work with ${ohpeneerName.replace(/-+/g, ' ').split(' ')[0]}`}
          />
        )}
      </RightOnSmaller>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[<Text isMobile {...leftText} />]}
            >
              <Box row>
                <Box col={0.4}>
                  <Text {...leftText} />
                </Box>
                <Box
                  col={0.6}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Quote
                    variant="light"
                    name={ohpeneer.name}
                    jobTitle={ohpeneer.function}
                    maxWidth="55rem"
                    inProp={inProp}
                  >
                    {ohpeneer.quote}
                  </Quote>
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
    </>
  )
}

export default OhpeneerPage
